import {
  richTextResolver,
  type StoryblokRichTextNode,
} from '@storyblok/richtext'
import {
  Children,
  createElement,
  isValidElement,
  type PropsWithChildren,
  type ReactElement,
} from 'react'

import { type StoryblokRichtextType } from './model/storyblokTypes.generated'

// Source https://github.com/storyblok/richtext/blob/869692e4272edacd5c6a131ace80fc501c1f19b1/playground/react/src/App.tsx

const camelCase = (str: string): string =>
  str.replaceAll(/-[a-z]/gu, (substring) => substring[1].toUpperCase())

const convertStyleStringToObject = (
  styleString: string,
): Record<string, string> => {
  const style: Record<string, string> = {}

  for (const property of styleString.split(';')) {
    const [key, value] = property.split(':').map((item) => item.trim())

    if (key && value) {
      style[camelCase(key)] = value
    }
  }

  return style
}

/**
 * Recursively converts HTML attributes in a React element tree to their JSX property names.
 */
const convertAttributesInElement = (
  element: ReactElement | ReactElement[],
): ReactElement | ReactElement[] => {
  if (Array.isArray(element)) {
    return element.map((el) => convertAttributesInElement(el)) as ReactElement[]
  }

  // Base case: if the element is not a React element, return it unchanged.
  if (!isValidElement(element)) {
    return element
  }

  // Convert attributes of the current element.
  const attributeMap: Record<string, string> = {
    class: 'className',
    for: 'htmlFor',
    targetAttr: 'targetattr',
  }

  const newProps: Record<string, unknown> = {}

  for (const [key, value] of Object.entries(
    element.props as Record<string, unknown>,
  )) {
    const mappedKey = attributeMap[key] || key
    const convertedValue =
      key === 'style' && typeof value === 'string'
        ? convertStyleStringToObject(value)
        : value

    newProps[mappedKey] = convertedValue
  }

  newProps.key = element.key

  // Process children recursively.
  const children = Children.map(
    (element.props as PropsWithChildren).children,
    (child) => convertAttributesInElement(child as ReactElement),
  )

  // Clone the element with the new properties and updated children.
  return createElement(element.type, newProps, children)
}

const { render } = richTextResolver<ReactElement>({
  keyedResolvers: true,
  renderFn: createElement,

  /**
   * By default, HTML entities are encoded.
   * See https://github.com/storyblok/richtext/blob/d1311ae166eb1a63ee5cc08099bae0c022fcf5d6/src/richtext.ts#L38
   *
   * React takes care of HTML entities. Prevent text encoding.
   * See https://github.com/storyblok/richtext/blob/d1311ae166eb1a63ee5cc08099bae0c022fcf5d6/src/richtext.ts#L147-L163
   */
  textFn: (text) => text as unknown as ReactElement,
})

export const cmsRenderRichText = (
  richText: StoryblokRichtextType,
): ReactElement | ReactElement[] =>
  convertAttributesInElement(
    render(richText as StoryblokRichTextNode<ReactElement>),
  )
