import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC } from 'react'

import { cmsRenderRichText } from '../../cmsRenderRichText'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokRichTextType } from '../../model/storyblokTypes.generated'

import styles from './StoryblokRichText.module.css'

export const StoryblokRichText: FC<
  StoryblokComponentProps<StoryblokRichTextType>
> = ({ blok }) => {
  const { content } = blok

  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx('prose', styles['storyblok-rich-text'])}
    >
      {cmsRenderRichText(content)}
    </div>
  )
}
